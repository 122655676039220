import group10 from "../images/designeasy/0.jpg";
import group11 from "../images/designeasy/1.jpg";
import group12 from "../images/designeasy/2.jpg";
import group13 from "../images/designeasy/3.jpg";
import group14 from "../images/designeasy/4.jpg";
import group15 from "../images/designeasy/5.jpg";
import group16 from "../images/designeasy/6.jpg";
import group17 from "../images/designeasy/7.jpg";
import group18 from "../images/designeasy/8.jpg";
import group20 from "../images/outlanddenim/00.jpg";
import group21 from "../images/outlanddenim/01.jpg";
import group22 from "../images/outlanddenim/02.jpg";
import group23 from "../images/outlanddenim/03.jpg";
import group24 from "../images/outlanddenim/04.jpg";
import group25 from "../images/outlanddenim/05.jpg";
import group26 from "../images/outlanddenim/06.jpg";
import group27 from "../images/outlanddenim/07.jpg";
import group28 from "../images/outlanddenim/08.jpg";
import group29 from "../images/outlanddenim/09.jpg";
import group210 from "../images/outlanddenim/010.jpg";
import group211 from "../images/outlanddenim/011.jpg";
import group212 from "../images/outlanddenim/1.jpg";
import group213 from "../images/outlanddenim/30.jpg";
import group214 from "../images/outlanddenim/31.jpg";
import group215 from "../images/outlanddenim/32.jpg";
import group216 from "../images/outlanddenim/33.jpg";
import group217 from "../images/outlanddenim/34.jpg";
import group218 from "../images/outlanddenim/35.jpg";
import group219 from "../images/outlanddenim/36.jpg";
import group220 from "../images/outlanddenim/37.jpg";
import group221 from "../images/outlanddenim/38.jpg";
import group222 from "../images/outlanddenim/39.jpg";
import group229 from "../images/thecontentdivision/1.jpg";
import group230 from "../images/thecontentdivision/2.jpg";
import group231 from "../images/thecontentdivision/3.jpg";
import group232 from "../images/thecontentdivision/4.jpg";
import group233 from "../images/thecontentdivision/5.jpg";
import group234 from "../images/thecontentdivision/6.jpg";
import group235 from "../images/thecontentdivision/7.jpg";
import group236 from "../images/thecontentdivision/8.jpg";
import group237 from "../images/thecontentdivision/9.jpg";
import group223 from "../images/athleteassessments/athleteass_home.jpg";
import group224 from "../images/athleteassessments/athleteass_aboutbo.jpg";
import group225 from "../images/athleteassessments/athletetough_home.jpg";
import group226 from "../images/athleteassessments/athletetough_about.jpg";
import group227 from "../images/athleteassessments/team8_home.jpg";
import group228 from "../images/athleteassessments/team8client.jpg";

import homepage from "../images/outlanddenim/Homepage.jpg";
import ourstorypage from "../images/outlanddenim/ourstorypage.jpg";
import ourbusinessmodel from "../images/outlanddenim/ourbusinessmodel.jpg";
import ourprocess from "../images/outlanddenim/ourprocess.jpg";
import ourstory from "../images/outlanddenim/ourstory.jpg";
import outland_journal from "../images/outlanddenim/outland_journal.jpg";
import productpage from "../images/outlanddenim/productpage.jpg";



const Careers = [

  {
    id: "1",
    title: "The Content Division",
    date: "Oct 2020 - Present",
    category: "Wordpress Developer",
    description: [
      {
        id: "a",
        para:
          "As the development lead at The Content Division, I helped bring digital projects and platforms to life. I worked hand-in-hand with the team to create user experience-led solutions for websites. My responsibilities were both back-end and front-end development, including creating custom themes and plugins. I Utilized the following skills HTML5, css3, js, php, elementor, after effects."
      },
      {
        id: "b",
        para:
          "Below are examples of my work"
      }
    ],
    button1: [
      {
        id: "1a",
        desc: "Vaulta",
        link:
          "https://www.vaulta.com.au/"
      },
      {
        id: "2a",
        desc: "Cryptoloc",
        link:
          "https://cryptoloc.com/"
      },
      {
        id: "3a",
        desc: "The Sex Blog",
        link:
          "http://thesexblog.com.au/"
      },
      {
        id: "4a",
        desc: "Britts List",
        link:
          "https://www.brittslist.com.au/"
      },
      {
        id: "5a",
        desc: "VoteSe",
        link:
          "https://votese.tcddev.com.au/"
      },
      {
        id: "6a",
        desc: "Surepact",
        link:
          "https://surepact.tcddev.com.au/"
      },
      {
        id: "7a",
        desc: "Jimbour",
        link:
          "https://jimbour2.tcddev.com.au/"
      },
    ],
    gallery: [
      {
        id: "1a",
        photo: group229
      },
      {
        id: "2a",
        photo: group230
      },
      {
        id: "3a",
        photo: group231
      },
      {
        id: "4a",
        photo: group232
      },
      {
        id: "5a",
        photo: group233
      },
      {
        id: "6a",
        photo: group234
      },
      {
        id: "7a",
        photo: group235
      },
      {
        id: "8a",
        photo: group236
      },
      {
        id: "9a",
        photo: group237
      }
    ]
  },
  {
    id: "2",
    title: "Athlete Assessments",
    date: "Feb 2020 - Oct 2020",
    category: "Wordpress Developer",
    description: [
      {
        id: "a",
        para:
          "My employment at Athlete Assessments was primarily to upgrade and maintain the existing website as well as increasing traffic to the site. The upgrades were a complete overhaul to the existing older websites. The end result was a much better user experience. This included custom graphics, custom themes, widgets, parallax effects, and lottie animations. This role saw me using my full skill set."
      },
      {
        id: "b",
        para:
          "Below are examples of my work"
      }
    ],
    button1: [
      {
        id: "1a",
        desc: "Athlete Assessments",
        link:
          "https://www.athleteassessments.com/"
      },
      {
        id: "2a",
        desc: "Athlete Tough",
        link:
          "https://athletetough.com/"
      },
      {
        id: "3a",
        desc: "Team8",
        link:
          "https://team8.com.au/"
      }
    ],
    gallery: [
      {
        id: "1a",
        photo: group223
      },
      {
        id: "2a",
        photo: group224
      },
      {
        id: "3a",
        photo: group225
      },
      {
        id: "4a",
        photo: group226
      },
      {
        id: "5a",
        photo: group227
      },
      {
        id: "6a",
        photo: group228
      }
    ]
  },
  {
    id: "3",
    title: "Outland Denim",
    date: "Aug 2016 - Feb 2019",
    category: "Front-end Developer",
    description: [
      {
        id: "a",
        para:
          "My role began in Aug 2016, exactly 8 weeks until the official launch of the product website. After a failed launch in Feb 2017 the company was cut back to 1 and ½ paid workers and for some-time the staff had to fulfill multiple job roles. After much hard work for close to a year, and new investors Outland Denim now employs close to 15 in Australia and 90 overseas. In October 2018 the Jeans were even worn by the Dutchess Meghan Markle! My employment was official as a Front end developer but it is necessary to say that for the duration of my employ it was 50% Front End Development and 50% Graphic design. Graphic Design included digital and print. To briefly list, photo editing, web mocks, digital e-books, prints such as flyers, and look books. Front-end development included coding up final mocks into custom-themed web pages using b@se tags for Neto and liquid for Shopify. JS was used when necessary as were JSON elements. This was a very time challenging and enjoyable role."
      }
    ],
    button1: [
      {
        id: "1a",
        desc: "Dropbox",
        link:
          "https://www.dropbox.com/sh/2mg94ordjo7xmay/AAB-CYTt_KLISGRsTwfE97wfa?dl=0"
      },
      {
        id: "2a",
        desc: "Outland Denim Website",
        link: "https://www.outlanddenim.com.au"
      }
    ],
    gallery: [
      {
        id: "homepage",
        photo: homepage
      },
      {
        id: "ourstorypage",
        photo: ourstorypage
      }, {
        id: "ourbusinessmodel",
        photo: ourbusinessmodel
      },
      {
        id: "ourprocess",
        photo: ourprocess
      }, {
        id: "ourstory",
        photo: ourstory
      },
      {
        id: "outland_journal",
        photo: outland_journal
      },
      {
        id: "productpage",
        photo: productpage
      },
      {
        id: "1a",
        photo: group20
      },
      {
        id: "2a",
        photo: group21
      },
      {
        id: "3a",
        photo: group22
      },
      {
        id: "4a",
        photo: group23
      },
      {
        id: "5a",
        photo: group24
      },
      {
        id: "6a",
        photo: group25
      },
      {
        id: "7a",
        photo: group26
      },
      {
        id: "8a",
        photo: group27
      },
      {
        id: "9a",
        photo: group28
      },
      {
        id: "10a",
        photo: group29
      },
      {
        id: "11a",
        photo: group210
      },
      {
        id: "12a",
        photo: group211
      },
      {
        id: "13a",
        photo: group212
      },
      {
        id: "14a",
        photo: group213
      },
      {
        id: "15a",
        photo: group214
      },
      {
        id: "16a",
        photo: group215
      },
      {
        id: "23a",
        photo: group216
      },
      {
        id: "17a",
        photo: group217
      },
      {
        id: "18a",
        photo: group218
      },
      {
        id: "19a",
        photo: group219
      },
      {
        id: "20a",
        photo: group220
      },
      {
        id: "21a",
        photo: group221
      },
      {
        id: "22a",
        photo: group222
      }
    ]
  },
  {
    id: "4",
    title: "DesignEasy",
    date: "Nov 2011 - May 2016",
    category: "Web Designer",
    description: [
      {
        id: "a",
        para:
          "My first full-time paid position as a Web designer it was for a small boutique web design company known as DesignEasy located in Underwood Queensland. With our small team of 5 to 6 staff, we provided solutions to approximately 1500 small businesses throughout Queensland, which proved to be unrealistically busy some weeks! Throughout my employment, at DesignEasy I could easily say that I created over five hundred website drafts for new and potential customers. My role included: customer contact, mockup, drafting, and lastly coding the customer website into Open Cart or WordPress."
      }
    ],
    button1: [
      {
        id: "1a",
        desc: "Dropbox",
        link:
          "https://www.dropbox.com/sh/c0pxwdb8hwgxacv/AADQ8VQisNChXbiTxPLKjZqra?dl=0"
      }
    ],
    gallery: [
      {
        id: "1a",
        photo: group10
      },
      {
        id: "2a",
        photo: group11
      },
      {
        id: "3a",
        photo: group12
      },
      {
        id: "4a",
        photo: group13
      },
      {
        id: "5a",
        photo: group14
      },
      {
        id: "6a",
        photo: group15
      },
      {
        id: "7a",
        photo: group16
      },
      {
        id: "8a",
        photo: group17
      },
      {
        id: "9a",
        photo: group18
      }
    ]
  },
  {
    id: "5",
    title: "Stellar Adelaide",
    date: "Aug 2007 - Dec 2010",
    category: "Inbound Consultant",
    description: [
      {
        id: "a",
        para:
          "This position included Managing large amounts of inbound calls for Telstra in a timely manner. Following call center “scripts” when handling different topics. Identifying customers' needs, clarify information, documenting and problemsolving issues and providing solutions."
      }
    ],
    button1: [
      {
        id: "1a",
        desc: "Stellar Website",
        link: "https://www.stellarbpo.com.au/"
      }
    ],
    gallery: []
  }
];

export default Careers;
